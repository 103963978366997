angular.module('app')
    .component('settlements', {
        templateUrl: '/static/templates/accounts/tabs/settlements.html',
        controller: Settlements,
        controllerAs: 'ctrl'
    });

function Settlements($log, $stateParams, accountService, bankingService) {

    var vm = this;
    vm.driverReference = $stateParams.driverReference;
    vm.accountReference = $stateParams.accountReference;
    vm.accountingOwnerReference = $stateParams.accountingOwnerReference;
    vm.settlements = [];
    vm.error = undefined;
    vm.loading = false;
    vm.pagedSet = {page: 0, totalPages: 0};
    vm.limit = 10;
    vm.disableLoadMore = vm.disableLoadMoreTranx = false;

    vm.loadMore = loadMore;
    vm.toggleSelected = toggleSelected;
    vm.search = search;
    vm.clearSearch = clearSearch;

    vm.$onInit = init;

    function init() {
        $log.debug("Settlements Controller initialized");
        listSettlements(0);
    }

    function loadMore() {
        listSettlements(vm.pagedSet.page);
    }

    function listSettlements(page) {
        if (page === 0) {
            vm.methods = [];
        }

        vm.loadingMore = vm.disableLoadMore = true;

        var params = getParams();

        // var tempDriverReference = 'a6961e55-c58f-11e6-9c11-c3631e4f6a88';
        // var tempAccountReference = 'a6961e54-c58f-11e6-9c11-db49951137f4';
        //
        // vm.driverReference = tempDriverReference;
        // vm.accountReference = tempAccountReference;

        accountService.getSettlementList(vm.accountingOwnerReference, vm.accountReference, params)
            .then(function (response) {

                var settlements = angular.forEach(response.data, function (settlement) {
                    settlement.selected = false;
                });

                vm.settlements = vm.settlements.concat(settlements);

                vm.pagedSet = response.header;

            }, function (error) {

                vm.error = error;

            })['finally'](function () {

            vm.loading = false;
            vm.loadingMore = false;
            vm.disableLoadMore = (vm.pagedSet.page >= vm.pagedSet.totalPages);

        });
    }

    function search() {
        var params = getParams();

        vm.settlements = [];
        vm.pagedSet.page = 0;

        listSettlements(0);

    }

    function getParams() {
        return {
            limit: 10,
            offset: (vm.pagedSet.page * vm.limit),
            fromDate: getFromDate(),
            toDate: getToDate()
        };
    }

    function getFromDate() {
        if (_.isUndefined(vm.fromDate)) return undefined;
        return moment(vm.fromDate).startOf('day').format('YYYYMMDDHHmmss');
    }

    function getToDate() {
        if (_.isUndefined(vm.toDate)) return undefined;
        return moment(vm.toDate).endOf('day').format('YYYYMMDDHHmmss');
    }

    function toggleSelected(settlement) {

        if (!_.isEmpty(settlement.transactions)) {

            var params = {
                limit: 10,
                offset: (vm.tranxPagedSet.page * vm.limit),
                settlementReference: settlement.settlementReference
            };

            bankingService.getTransactions(params)
                .then(function (response) {
                    var transactions = response.data;
                    settlement.transactios = transactions;
                    vm.pagedSet = response.header;
                    vm.disableLoadMore = (vm.pagedSet.page >= vm.pagedSet.totalPages);
                }, function (error) {
                    $log.debug(error);
                    vm.disableLoadMore = true;
                })['finally'](function () {
                vm.loading = false;
                vm.loadingMore = false;

            });
        }
        settlement.selected = !settlement.selected;
    }

    function clearSearch() {
        vm.fromDate = undefined;
        vm.toDate = undefined;
    }

}